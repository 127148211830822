body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

@font-face {
  font-family: 'Almoni ML v5 AAA';
  src: url('/fonts/almoni-light-aaa.woff2') format('woff2');
}

@font-face {
  font-family: 'Almoni ML v5 AAA bold';
  src: url('/fonts/almoni-bold-aaa.woff2') format('woff2');
}

@font-face {
  font-family: 'Mugrabi v2 AAA';
  src: url('/fonts/mugrabi-light-aaa.woff2') format('woff2');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.r_float {
  float: right !important;
}

.finish-all-chapter {
  float: right;
  width: 100%;
  font-weight: 600;
  display: flex !important;
  align-items: center;
}

.finish-all-chapter input{
  margin-left: 5px !important;
}

.sium-main {
  width: 100%
}

.sium-button-container {
  margin: 5px auto;
  text-align: center;
  background-color: #E6FAFF;
}

.sium-button,

.sium-button:focus-visible,
.sium-button:active
{
  margin: 15px 10px;
  height: 60px;
  min-width: 50%;
  background-color: cadetblue !important;
  color: white !important;
}

.sium-button:hover {
  background-color: #33585A !important;
}

.sium-status-button {
  min-width: 200px !important;
  height: 50px;
  padding-top: 10px !important;
  background-color: #5DC9E0 !important;
  color: #3A499E !important;
  font-family: "Almoni ML v5 AAA bold" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  border-radius: 5px;
  border: none !important;
}
.sium-status-button:hover,
.sium-status-button:active,
.sium-status-button:focus-visible
{
  color: #5DC9E0 !important;
  background-color: #1F2B70 !important;
}

.sium-status-button::after {
  margin-right: 5px;
}

.new-sium-button,
.new-sium-button:active
{
  margin: 15px 10px;
  height: 50px;
  min-width: 200px;
  background-color: #5DC9E0 !important;
  color: #3A499E !important;
  font-family: "Almoni ML v5 AAA bold" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  border-radius: 5px
}

.new-sium-button:focus-visible,
.new-sium-button:hover {
  color: #5DC9E0 !important;
  background-color: #1F2B70 !important;
}

.sorting-container {
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .sorting-container {
    flex-direction: column;
  }
}

.sorting-label {
  min-width: 60px;
  margin-left: 10px;
}

.welcome-buttons {
  display: flex;
}

.welcome-buttons .new-sium-button {
  width: 45%;
  font-size: 30px !important;
  height: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-button {
  color: #5DC9E0 !important;
  background-color: #1F2B70 !important;
}

.dark-button:hover {
  background-color: #5DC9E0 !important;
  color: #3A499E !important;
}

@media (max-width: 500px) {
  .sium-button,
  .sium-button:hover,
  .sium-button:active,
  .sium-button:focus-visible,
  .sium-status-button,
  .sium-status-button:hover,
  .sium-status-button:active,
  .sium-status-button:focus-visible {
    min-width: 190px !important;
  }
}

@media (max-width: 600px) {
  .welcome-buttons {
    flex-wrap: wrap;
  }
  .welcome-buttons .new-sium-button {
    width: calc(100% - 20px);
  }
}

.sium-main h1 {
  display: inline;
  margin: 10px 10px;
}

.sium-main h2 {
  font-family: "Mugrabi v2 AAA";
  font-size: 45px;
}

.sium-main h3 {
  margin: 5px 0 0 0;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.sium-main-container {
  background-color: #E6FAFF;
  padding: 10px;
  color: #414A87;
  margin: 15px auto;
  min-width: 380px;
  width: 98%;
  direction: rtl;
}

.sium-main-container form {
  padding: 10px;
}

.sium-main-container form input {
  margin: 10px 0;
}

.sium-main-header {
  display: flex;
  justify-content: space-between;
}

form .tiny-input {
  margin: 10px auto !important;
  max-width: 400px;
}

@media (min-width: 1032px) {
  .sium-main-container {
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 850px;
  }
}

@media (max-width: 500px) {
  .card-body {
    padding: 8px !important;
  }
}

.update-status h2 {
  text-align: center;
  margin-top: 15px;
}

.hidden {
  display: none;
}


/* Progress bars */
.progress-container {
  max-width: 180px;
  margin: 20px auto 0 auto !important;
  text-align: center;
}

.progress-message {
  min-width: 105px;
}

.user-progress-container,
.sium-progress-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.progress{
  width: 150px !important;
  height: 150px !important;
  line-height: 150px !important;
  background: none !important;
  margin: 0 auto !important;
  box-shadow: none !important;
  position: relative !important;
}
.progress:after{
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  border: 2px solid #fff !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
.progress > span{
  width: 50% !important;
  height: 100% !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  z-index: 1 !important;
}
.progress .progress-left{
  left: 0 !important;
}
.progress .progress-bar{
  width: 100%;
  height: 100%;
  background: none;
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar{
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right{
  right: 0;
}
.progress .progress-right .progress-bar{
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress .progress-value{
  width: 85%;
  height: 85%;
  border-radius: 50%;
  border: 2px solid #ebebeb;
  font-size: 32px;
  line-height: 125px;
  text-align: center;
  position: absolute;
  top: 7.5%;
  left: 7.5%;
}
.percent-0 .progress-right .progress-bar{
  animation: loading-0 1.5s linear forwards;
}
.percent-10 .progress-right .progress-bar{
  animation: loading-1 1.5s linear forwards;
}
.percent-20 .progress-right .progress-bar{
  animation: loading-2 1.5s linear forwards;
}
.percent-30 .progress-right .progress-bar{
  animation: loading-3 1.5s linear forwards;
}
.percent-40 .progress-right .progress-bar{
  animation: loading-4 1.5s linear forwards;
}
.percent-50 .progress-right .progress-bar{
  animation: loading-5 1.5s linear forwards;
}
.percent-60 .progress-right .progress-bar,
.percent-70 .progress-right .progress-bar,
.percent-80 .progress-right .progress-bar,
.percent-90 .progress-right .progress-bar,
.percent-100 .progress-right .progress-bar{
  animation: loading-5 1.5s linear forwards;
}
.percent-60 .progress-left .progress-bar{
  animation: loading-1 0.3s linear forwards 1.5s;
}
.percent-70 .progress-left .progress-bar{
  animation: loading-2 0.6s linear forwards 1.5s;
}
.percent-80 .progress-left .progress-bar{
  animation: loading-3 0.9s linear forwards 1.5s;
}
.percent-90 .progress-left .progress-bar{
  animation: loading-4 1.2s linear forwards 1.5s;
}
.percent-100 .progress-left .progress-bar{
  animation: loading-5 1.5s linear forwards 1.5s;
}
.progress.red .progress-bar{
  border-color: #ed687c;
}
.progress.red .progress-value{
  color: #ed687c;
}
.progress.green .progress-bar{
  border-color: #1abc9c;
}
.progress.green .progress-value{
  color: #1abc9c;
  font-weight: bold;
}
@keyframes loading-0{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes loading-1{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}
@keyframes loading-2{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
  }
}
@keyframes loading-3{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
  }
}
@keyframes loading-4{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-5{
  0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(126deg);
    transform: rotate(180deg);
  }
}
@media only screen and (max-width: 990px){
  .progress{ margin-bottom: 20px; }
}
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  25% {
    transform: scale3d(1.08, 1.08, 1.08);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 2s;
  animation-iteration-count: infinite !important;
}

@keyframes page-jump {
  0% {
    transform: scale(1.1, 0.9);
    background-color: #A5D6A7;
    border-color: #A5D6A7;
    box-shadow: 0 0 10px 3px rgba(165, 214, 167, 0.7);
  }
  15% {
    transform: scale(0.9, 1.1) translateY(-0.5rem);
    background-color: #81C784;
    border-color: #81C784;
    box-shadow: none;
  }
  25% {
    transform: scale(1);
    background-color: #A5D6A7;
    border-color: #A5D6A7;
    box-shadow: 0 0 10px 3px rgba(165, 214, 167, 0.7);
  }
}

.page-jump {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #A5D6A7;
  border: 2px solid #A5D6A7;
  border-radius: 5px;
  cursor: pointer;
  animation: page-jump 3s ease-in-out infinite alternate;
}

.masehet-box {
  max-width: 300px;
}
.masehet-box .perek-box {
  width: 85px;
}

.masehet-box .add-remove-masehet {
  width: 255px;
}

.masehet-was-selected {
  padding: 0 5px;
  color: darkblue;
}

.toast {
  display: flex;
  flex-direction: row;
  padding: 10px;
  color: #052c65 !important;
  background-color: #cfe2ff !important;
  direction: rtl;
  margin-top: 20px;
}

.toast-container .btn-close {
  position: sticky !important;
}

.toast-container .toast-body {
  display: inline-block;
  width: 90%;
}

.sium-logo {
  width: 150px;
  height: 154px;
  margin: 20px auto !important;
  text-align: center;
}

.sium-logo .sium-logo-img {
  border-radius: 50px;
  height:154px;
  width: 150px;
  display: block;
  border-style: none;
  background-size: 100%;
  background-image: url("/public/siumShasLogo.jpeg");
}

@media (max-width: 800px) {
  .sium-logo {
    width: 100px;
    height: 105px;
  }

  .sium-logo .sium-logo-img {
    height: 105px;
    width: 100px;
  }

}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}


.margin-left-10 {
  margin-left: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

 .page-jump-msg {
   margin-top: -20px;
   animation: page-jump 3s ease-in-out infinite alternate;
 }

 .grid {
   display: grid;
 }

 .page-button {
   width: 55px;
 }

 .center-text {
   text-align: center;
 }

 .right-text {
   text-align: right;
 }

 .basad {
   text-align: right;
   font-weight: bold;
   font-size: small;
   width: 60%;
 }

 .font-size-small {
   font-size: small;
 }

 .header-update-status {
   font-weight: 600;
   font-size: 28px;
 }

.basad-with-return {
  position: relative;
}

.return-container{
  display: block;
  position: absolute;
  top: -10px;
  left: 0px;
}

.return-button,
.return-button:hover,
.return-button:active,
.return-button:focus-visible {
  height: 35px !important;
  min-width: 0px !important;
}

.backgroud-white {
  background-color: white !important;
}

.chapter-button {
  width: 80px;
}

.daf-button {
  width: 42px;
  padding: 2px !important;
}

.daf-button-little {
  width: 35px;
  padding: 2px !important;
  font-size: 14px !important
}

.mishna-button-little {
  width: 60px;
  padding: 2px !important;
  font-size: 13px !important
}

.mishna-button {
  width: 75px;
  padding: 2px !important;
  font-size: 15px !important
}

.chapters-list {
  display: flex;
  flex-direction: row;
  padding: 10px !important;
  flex-wrap: wrap;
}

.sort-button {
  width: 50%;
  min-width: 170px;
  border-radius: 20px !important;
  border-color: #627bea !important;
  --bs-btn-color: #627bea !important;
  --bs-btn-active-bg: #627bea !important;
  --bs-btn-hover-bg: #627bea !important;
}

.sort-button:hover {
  background-color: #627bea !important;
  color: white !important;
}

.modal-header .btn-close {
  margin: 0 !important;
}

.different-percent {
  padding: 0 5px;
  direction: ltr;
}

.sium-bottom-page {
  text-align: center;
  font-size: 14px;
}

.sium-status-option,
.sium-status-option:hover {
  width: 160px;
  height: 160px;
  display: inline-block;
  margin: 10px;
  padding: 20px;
  text-align: center;
  border: 5px solid #414A87;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  color: #414a87;
}

.sium-status-option.collapsed {
  background-color: initial;
  font-weight: initial;
}

.sium-status-option:hover,
.sium-status-option.collapsed:hover {
  background-color: var(--bs-primary-bg-subtle);
  font-weight: bold;
}

.sium-status-option svg {
  width: 82px;
  height: 82px;
}

@media (max-width: 992px) {
  .sium-status-option,
  .sium-status-option:hover {
    width: 120px;
    height: 120px;
    font-size: 12.5px;
  }
  .sium-status-option svg {
    width: 50px;
    height:50px;
  }
}

.sium-status-options-container {
  margin: 20px auto;
  text-align: center;
}

.menu-body {
  margin: 20px auto;
  text-align: center;
}

.sium-content {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
}

.sharing-tip {
  background-color: white;
  margin: 20px;
  padding: 10px 10px 0 10px;
  position: relative;
  text-align: center;
  max-width: 350px;
}

@media (max-width: 600px) {
  .sharing-tip {
    width: 90% !important;
  }
}

.sharing-tip svg {
  width: 40px;
  height: 40px;
  top: 15px;
  position: absolute;
}

.sharing-tip span {
  padding: 12px 60px 0 5px;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  text-align: right;
  font-weight: 700;
}

.sharing-tip .btn {
  margin: 10px;
  background-color: cadetblue !important;
  color: white !important;
  min-width: 90px;
}

.sharing-tip .btn:hover {
  background-color: #33585A !important;
}

.fil0 {fill: rgb(37, 140, 230)}
.fil1 {fill:white}

@media (max-width: 400px) {
  .modal-backdrop {
    top: 0 !important;
    right: 0 !important;
    width: 400px !important;
    height: 100% !important;
  }
  .modal {
    padding-right: 0 !important;
  }
}

.menu-icon {
  width: 40px;
  float: left;
}

.menu-icon:hover {
  background-color: var(--bs-primary-bg-subtle);
}

.lomdim-list-container {
  margin: 15px auto 0 auto;
  max-width: 400px;
}

.lomdim-list-title {
  padding: 5px 10px 5px 10px;
  background-color: var(--bs-primary-bg-subtle);
}

.lomdim-list {
  padding: 0 !important;
}

.carousel-item .lomdim-list-container {
  height: 250px;
  overflow: hidden;
  position: relative;
}

.carousel-item .lomdim-list {
  max-height: 100%;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.carousel-item .list-group-item {
  break-inside: avoid;
}

.share-display-sium,
.share-display-sium:focus-visible,
.share-display-sium:active {
  width: 53%;
  margin: 15px auto;
  height: 40px;
}

.example-sium,
.example-sium:active,
.example-sium:focus-visible {
  margin: 0 auto;
}

.sium-titles {
  display: flex;
  text-align: center;
  margin-bottom: 20px;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.sium-titles .sium-button,
.sium-titles .sium-button:hover,
.sium-titles .sium-button:focus-visible,
.sium-titles .sium-button:active {
  min-width: 100px !important;
  width: 100px !important;
  margin: 0 auto;
  height: 40px;
}

.sium-titles h2 {
  max-width: 600px;
}

.sium-dates-container {
  text-align: center;
  margin: 0 auto;
}

.form-floating .input-label {
  right: 0 !important;
  /*transform: translateY(-0.8rem) !important;*/
  /*font-size: small !important;*/
}

.welcome-container {
  background-color: #F5FAFE;
  height: 100vh;
}

.welcome-container .header {
  width: 100%;
  height: 80px;
}

.welcome-container .row {
  display: flex;
  width: 100%;
  background-color: rgb(245, 250, 254);
  align-items: center;
  justify-content: center;
}

.header-icon {
  float: right;
  margin: 20px 30px 0 0;
}

.header-new-sium {
  float: left;
  margin-left: 15px;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .welcome-container .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-new-sium {
    display: none;
  }
}

.welcome-text-container {
  text-align: right;
  padding: 60px 90px 0 20px!important;
  font-family: 'Almoni ML v5 AAA';
  color: #1F2B70;
  direction: rtl;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .welcome-text-container {
    padding: 60px 20px 0 !important;
  }
}

.welcome-text-container h3 {
  font-size: 60px;
  font-weight: 100;
  line-height: 85px;
  letter-spacing: 0em;
}

.welcome-text-container h1 {
  font-family: "Mugrabi v2 AAA";
  font-size: 175px;
  font-weight: 300;
  line-height: 120px;
  letter-spacing: 0em;
  margin-bottom: -10px;
}

.welcome-text-container h2 {
  font-family: "Mugrabi v2 AAA";
  font-size: 60px;
  font-weight: 300;
  margin-bottom: 30px;
  /*line-height: 120px;*/
  letter-spacing: 0em;
}

@media (max-width: 400px) {
  .welcome-text-container h1 {
    font-size: 125px;
  }
  .welcome-text-container h2 {
    font-size: 40px;
  }
}

.welcome-text {
  font-family: "Almoni ML v5 AAA";
  font-size: 23px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
}

.welcome-text-bold {
  font-family: "Almoni ML v5 AAA bold";
}

.tania-image {
  margin: auto;
  aspect-ratio: 1/1;
  padding: 30px 0 0 20px;
}

@media (max-width: 991px) and (min-width: 750px) {
  .tania-image {
    width: 70%;
  }
}

@media (min-width: 500px) {
  .tania-image {
    max-height: calc(100vh - 80px);
    padding-bottom: 15px;
    min-height: 400px;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-img {
  width: 100px;
  height: 100px;
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.new-sium-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  direction: rtl;
  color: #1F2B70;
  min-height: 100vh;
  background-color: #F5FAFE;
  padding: 40px 0;
}

.new-sium-container .logo-icon {
  margin-bottom: 40px;
  cursor: pointer;
}

.new-sium-container h1 {
  width: 100%;
  font-family: "Mugrabi v2 AAA";
  font-weight: 300;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.new-sium-container h3 {
  font-family: "Almoni ML v5 AAA";
  font-weight: 300;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}

.new-sium-container .form-headers {
  margin-bottom: 20px;
}

.new-sium-container .new-sium-form {
  padding: 40px 30px 20px 30px;
  width: 900px;
  box-shadow: 0px 24px 50px 0px #1F2B7021;
  background-color: white;
  border-radius: 20px;
}

.new-sium-form .form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Almoni ML v5 AAA";
}
.exists-sium .input-row,
.new-sium-container .input-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.exists-sium .input-container,
.new-sium-form .input-container {
  width: calc(50% - 10px);
}

.exists-sium .full-width,
.new-sium-container .full-width {
  margin-bottom: 20px;
  width: 100%;
}

.exists-sium .input,
.new-sium-form .input {
  width: 100%;
  height: 64px;
  font-size: 22px;
  padding: 10px;
  border: 1px solid #3A499E;
  border-radius: 5px;
  direction: rtl;
  outline-color: #3A499E;
  color: #3A499E;
}

.exists-sium .input:focus-visible,
.exists-sium .input:hover,
.new-sium-form .input:focus-visible,
.new-sium-form .input:hover,
.new-sium-form .react-datepicker-wrapper input:focus-visible,
.new-sium-form .react-datepicker-wrapper input:hover {
  border: 2px solid #3A499E !important;
  outline-color: #3A499E;
  border-radius: 5px;
}

.new-sium-form .react-datepicker-wrapper {
  display: inline;
}

.new-sium-form .react-datepicker-wrapper input {
  width: 100%;
  height: 64px;
  font-size: 22px;
  padding: 10px;
  border: 1px solid #3A499E;
  border-radius: 5px;
}

.new-sium-form .sium-dates-picker {
  font-family: "Almoni ML v5 AAA bold";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.exists-sium .input::placeholder,
.new-sium-form .input::placeholder {
  font-size: 17px;
  color: #3A499E;
}

.exists-sium .input:focus::placeholder,
.new-sium-form .input:focus::placeholder {
  font-size: 12px;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.new-sium-form .new-sium-button {
  width: 100% !important;
  font-weight: 700 !important;
  font-family: "Almoni ML v5 AAA bold" !important;
  font-size: 35px !important;
  margin: 10px 0 !important;
  height: 80px;
}

.new-sium-container .sium-bottom-page {
  margin-top: 30px;
}

.new-sium-container .no-link {
  text-decoration: none;
  color: #1F2B70;
}

.exists-sium .exists-sium-btn-container {
  display: flex;
  align-items: center;
}

.exists-sium .exists-sium-btn {
  margin: 0 auto;
  width: 50%;
  height: 75px;
  font-size: 30px !important;
}

.exists-sium .forgot-btn {
  margin: 0 auto;
  width: 26%;
  height: 50px;
  font-size: 23px !important;
}

.exists-sium .send-forgot-id {
  margin: 0 auto;
  width: 100%;
  height: 65px;
  font-size: 30px !important;
}

@media (max-width: 950px) and (min-width: 550px) {
  .new-sium-container .new-sium-form {
    width: 96vw;
  }
}

@media (max-width: 550px) {
  .new-sium-container .new-sium-form {
    width: 96vw;
    padding: 20px 10px;
  }

  .new-sium-container .form-container {
    align-items: flex-start;
  }

  .new-sium-container .input-row {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .new-sium-form .input-container {
    width: calc(100% - 10px);
    margin-bottom: 10px;
  }

  .new-sium-container h1 {
    font-size: 40px;
    line-height: 35px;
    margin: 0;
  }

  .new-sium-container h3 {
    font-size: 20px;
    line-height: 40px;
  }

  .exists-sium .input-row {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .exists-sium .input-container {
    width: calc(100% - 10px);
    margin-bottom: 10px;
  }

  .exists-sium .exists-sium-btn {
    margin: 0;
    width: 100%;
  }

  .exists-sium .forgot-btn {
    width: 50%;
  }
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .exists-sium .input-row {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .exists-sium .input-container {
    width: calc(100% - 10px);
    margin-bottom: 10px;
  }
  .exists-sium .exists-sium-btn {
    margin: 0;
    width: 100%;
  }
  .exists-sium .forgot-btn {
    width: 50%;
  }
}

.fields-error-message {
  color: red;
  font-weight: 900;
}

.edit-sium-field {
  width: 117%;
}

.selected-masehet-color {
  color: var(--bs-accordion-active-color) !important;
  background-color: var(--bs-accordion-active-bg) !important;
}

.learning-links-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.daf-yomi-link {
  height: 40px;
  width: 180px;
  background-image: url("/public/dafYomi.gif");
}

.shitufta-link {
  height: 115px;
  width: 125px;
  background-image: url("/public/shitufta.png");
}

#confetti-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.updateStatusSpinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 16px;
  height: 16px;
  margin: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.days-left {
  margin: 0;
}

.user-days-left {
  font-size: 18px;
  margin: 20px;
}

.lomed-actions-list {
  padding-top: 10px !important;
  border: none !important;
  background-color: rgb(200 255 252) !important;
}

.lomed-actions-list li {
  margin-bottom: 3px;
}

.sium-button-container:hover .dropdown-menu {
  display: block;
}

.sium-button-container .dropdown-menu .dropdown_item-1,
.sium-button-container .dropdown-menu .dropdown_item-2,
.sium-button-container .dropdown-menu .dropdown_item-3 {
  transform-origin: top center;
  opacity: 0;
  animation: translateX 300ms ease-in-out forwards;
}

.sium-button-container .dropdown-menu .dropdown_item-1 {
  animation-delay: 60ms;
}

.sium-button-container .dropdown-menu .dropdown_item-2 {
  animation-delay: 120ms;
}

.sium-button-container .dropdown-menu .dropdown_item-3 {
  animation-delay: 180ms;
}

@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  80% {
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}


.days-left-content {
  display: inline;
  transform: translate(-50%, -50%);
  height: 160px;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  line-height: 40px;
}

.days-left-container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
  padding: 0 30px;
  position: relative;
  margin: 20px auto;
  max-width: 350px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
}

.days-left-container:before,
.days-left-container:after {
  position: absolute;
  top: 0;
  color: #16a085;
  font-size: 32px;
  line-height: 32px;
  -webkit-animation-name: days-left-opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: days-left-opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.days-left-container:before {
  content: ']';
  left: 0;
}

.days-left-container:after {
  content: '[';
  right: 0;
}

.days-left-text {
  display: inline;
  float: left;
  margin-left: 8px;
}

.days-left-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  -webkit-animation-name: days-left-change;
  -webkit-animation-duration: 8s; /* Adjusted duration for two items */
  -webkit-animation-iteration-count: infinite;
  animation-name: days-left-change;
  animation-duration: 8s; /* Adjusted duration for two items */
  animation-iteration-count: infinite;
}

.days-left-list-item {
  line-height: 40px;
  margin: 0;
}

@-webkit-keyframes days-left-opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes days-left-opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes days-left-change {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes days-left-change {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

.halukat-shas-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
}

.halukat-shas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.halukat-shas-label {
  margin-left: 15px;
  font-weight: bold;
  padding-top: 7px;
}

.haluka-button,
.include-masehet-button,
.include-masehet-button:focus-visible {
  width: 50%;
  font-weight: bold !important;
  border-radius: 20px !important;
  border-color: #1F2B70 !important;
  --bs-btn-color: #1F2B70  !important;
  --bs-btn-active-bg: #1F2B70 !important;
  --bs-btn-hover-bg: #1F2B70 !important;
}

.haluka-button:hover {
  background-color: #1F2B70 !important;
  color: white !important;
}

@media (min-width: 1050px) {
  .include-masehet-button:hover {
    background-color: #1F2B70 !important;
    color: white !important;
  }
}


.haluka-button {
  width: 170px;
  max-width: 300px;
}

.include-masehet-button {
  width: 80px;
}

.font-size-20 {
  font-size: 20px !important;
}

.add-user-modal,
.check-user-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-detail-modal {
  margin-top: 12px;
}

.user-detail-modal svg {
  width: 40px;
  height: 30px;
}

.badge {
  font-weight: 600 !important;

}

.last-badge {
  margin: 0 10px 0 5px;
}

@media (max-width: 470px) {
  .badge {
    margin-right: 5px;
    padding: 4px 3px !important;
  }
}

.edit-user-button {
  max-width: 150px;
  margin-top: 5px;
}

.edit-user-button span {
  margin-right: 10px;
  font-weight: 600;
}

.edit-user-button svg {
  margin-right: 0;
}

.update-status-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px
}

.share-display-sium {
  max-width: 200px;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.status-taken-button {
  color: white !important;
  border: 0.1px solid grey !important;
}

.masehet-title {
  min-width: 85px;
  display: inline-flex;
  text-align: right;
}

@media (max-width: 450px) {
  .masehet-title {
    min-width: 60px;
    width: 60px;
  }
}

.daf-click-header {
  margin: 8px !important;
  color: #414A87;
  font-size: 20px;
}

.chapters-options {
  direction: ltr;
}

.perut-dapim-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.perut-dapim-list {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
}

.perut-dapim-perek {
  padding: 3px 6px !important;
}

.mehinotToraniot {
  background-image: url("/public/mehinotToraniot.png");
  background-size: 100%;
  width: 400px;
  height: 190px;
}

@media (max-width: 450px) {
  .mehinotToraniot {
    width: 300px;
    height: 140px;
  }
}

.yacahdBeorcha {
  background-image: url("/public/yacahdBeorcha.png");
  background-size: 100%;
  width: 280px;
  height: 200px;
  margin-bottom: 5px;
}

@media (max-width: 450px) {
  .yacahdBeorcha {
    width: 220px;
    height: 160px;
  }
}

.chabad {
  background-image: url("/public/chabad.png");
  background-size: 100%;
  width: 450px;
  height: 190px;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .chabad {
    width: 300px;
    height: 140px;
  }
}

.uriel {
  background-image: url("/public/uriel.png");
  background-size: 100%;
  width: 270px;
  height: 270px;
}

@media (max-width: 450px) {
  .uriel {
    width: 200px;
    height: 200px;
  }
}

.accordion-button {
  text-align: right !important;
}

@media (max-width: 450px) {
  .accordion-button {
    padding: 15px 10px !important;
  }
}
